<!-- *************************** 我的产品 ****************************** -->
<template>
  <div class="preview">
    <h1 class="title">我的产品</h1>
    <div>
      <el-table :data="tableData" stripe border v-loading="loading">
        <el-table-column prop="type" label="产品类型">
          <template slot-scope="scope">{{ type[scope.row.type] }}</template>
        </el-table-column>
        <el-table-column prop="begin_time" label="购买时间"></el-table-column>
        <el-table-column prop="end_time" label="到期时间"></el-table-column>
        <el-table-column prop="is_active" label="是否续费">
          <template slot-scope="scope">
            <router-link to="/recharge">{{ scope.row.is_active ? '续费' : '重新购买' }}</router-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import {getMyProductApi} from "api/AdminCenter";

export default {
  data() {
    return {
      loading: true,
      tableData: [
        {
          begin_time: "2020-11-27 02:46:06",
          end_time: "2020-12-27 02:46:06",
          type: 3,
          is_active: true
        }
      ],
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      }
    };
  },
  mounted() {
    getMyProductApi().then(res => {
      if (res.code == 0) {
        this.loading = false;
        this.tableData = res.data;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.preview {
  background: #fff;
  padding: 50px;
  .title {
    margin-bottom: 50px;
    font-size: 20px;
    color: #1a1a1a;
    text-align: left;
  }
  ::v-deep .el-table td,
  ::v-deep .el-table th {
    text-align: center;
  }
  ::v-deep .el-table__body {
    width: 100% !important;
  }
  ::v-deep .el-table__header {
    width: 100% !important;
  }
  ::v-deep .el-table th {
    color: #fff;
    background: #4353b6;
  }
}
</style>